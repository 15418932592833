import { CURRENT_EXERCISES, CLEAR_EXERCISES } from '../../actions/exercise';

export default function exerciseCurrent(state = [], action) {
	switch (action.type) {
		case CURRENT_EXERCISES:
			return action.data;
		case CLEAR_EXERCISES:
			return [];
		default:
			return state;
	}
}