import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_ONE_EXERCISE = 'GRAB_ONE_EXERCISE';
export const CLEAR_EXERCISES = "CLEAR_EXERCISES";
export const CURRENT_EXERCISES = "CURRENT_EXERCISES";
export const GRAB_EXERCISE_NAMES = 'GRAB_EXERCISE_NAMES';
export const GRAB_EXERCISE_COLUMNS = 'GRAB_EXERCISE_COLUMNS';
export const GRAB_EXERCISE_COLUMN_OPTIONS = 'GRAB_EXERCISE_COLUMN_OPTIONS';
export const GRAB_EXERCISE_COLUMN_INFO = 'GRAB_EXERCISE_COLUMN_INFO';
export const GRAB_EXERCISE_ITEMS = 'GRAB_EXERCISE_ITEMS';

export const handleGrabExerciseNames = () => async dispatch => {
	console.log('starting grab exercise names');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/exercise/names")).then((snapshot) => {
		dispatch({
			type: GRAB_EXERCISE_NAMES,
			data: snapshot.val()
		})
	});
}

export const handleGrabExerciseColumnOptions = () => async dispatch => {
	console.log('starting grab exercise column options');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/exercise/columnOptions")).then((snapshot) => {
		dispatch({
			type: GRAB_EXERCISE_COLUMN_OPTIONS,
			data: snapshot.val()
		})
	});
}

export const handleGrabExerciseColumns = () => async dispatch => {
	console.log('starting grab exercise columns');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/exercise/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_EXERCISE_COLUMNS,
			data: snapshot.val()
		})
	});
}

export const handleCurrentExercises = (keys) => async dispatch => {
	//keys should be in an array
	dispatch({
		type: CURRENT_EXERCISES,
		data: keys
	})
}

export const handleGrabOneExercise = (key) => async dispatch => {
	console.log('starting grab one exercise');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "exercise/" + key)).then((snapshot) => {
		dispatch({
			type: GRAB_ONE_EXERCISE,
			key,
			data: snapshot.val()
		})
	});
}

export const handleGrabExerciseColumnInfo = () => async dispatch => {
	console.log('starting grab exercise column info');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/exercise/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_EXERCISE_COLUMN_INFO,
			data: snapshot.val()
		})
	});
}

export const handleGrabExerciseItems = () => async dispatch => {
	console.log('starting grab exercise items');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/exercise/items")).then((snapshot) => {
		dispatch({
			type: GRAB_EXERCISE_ITEMS,
			data: snapshot.val()
		})
	});
}

export function handleClearExercises() {
	return {
		type: CLEAR_EXERCISES
	}
}
