export const TOGGLE_INFO = 'TOGGLE_INFO';
export const CHANGE_LOADING = 'CHANGE_LOADING';


export const handleToggleInfo = () => async dispatch => {
	dispatch({
		type: TOGGLE_INFO
	})
}

export const handleChangeLoading = (bool) => async dispatch => {
	dispatch({
		type: CHANGE_LOADING,
		data: bool
	})
}