import { combineReducers } from 'redux';

import userStatus from './userStatus';
import userName from './userName';

import exercises from './exercise/exercises';
import exerciseNames from './exercise/exerciseNames';
import exerciseItems from './exercise/exerciseItems';
import exerciseCurrent from './exercise/exerciseCurrent';
import exerciseColumns from './exercise/exerciseColumns';
import exerciseColumnOptions from './exercise/exerciseColumnOptions';
import exerciseColumnInfo from './exercise/exerciseColumnInfo';

import food from './food/food';
import foodNames from './food/foodNames';
import foodCurrent from './food/foodCurrent';
import foodColumns from './food/foodColumns';
import foodColumnOptions from './food/foodColumnOptions';
import foodColumnInfo from './food/foodColumnInfo';

import muscle from './muscle/muscle';
import muscleNames from './muscle/muscleNames';
import muscleItems from './muscle/muscleItems';
import muscleCurrent from './muscle/muscleCurrent';
import muscleColumns from './muscle/muscleColumns';
import muscleColumnOptions from './muscle/muscleColumnOptions';
import muscleColumnInfo from './muscle/muscleColumnInfo';

import nutrient from './nutrient/nutrient';
import nutrientNames from './nutrient/nutrientNames';
import nutrientItems from './nutrient/nutrientItems';
import nutrientCurrent from './nutrient/nutrientCurrent';
import nutrientColumns from './nutrient/nutrientColumns';
import nutrientColumnOptions from './nutrient/nutrientColumnOptions';
import nutrientColumnInfo from './nutrient/nutrientColumnInfo';

import anaerobicSetType from './anaerobic/setType';
import anaerobicWorkoutType from './anaerobic/workoutType';

import rpe from './rpe';
import program from './program';

import loading from './loading';
import allItems from './allitems';
import allNames from './allnames';

export default combineReducers({
	userStatus,
	userName,

	exercises,
	exerciseNames,
	exerciseItems,
	exerciseCurrent,
	exerciseColumns,
	exerciseColumnOptions,
	exerciseColumnInfo,

	food,
	foodNames,
	foodCurrent,
	foodColumns,
	foodColumnOptions,
	foodColumnInfo,

	muscle,
	muscleNames,
	muscleItems,
	muscleCurrent,
	muscleColumns,
	muscleColumnOptions,
	muscleColumnInfo,

	nutrient,
	nutrientNames,
	nutrientItems,
	nutrientCurrent,
	nutrientColumns,
	nutrientColumnOptions,
	nutrientColumnInfo,

	anaerobicSetType,
	anaerobicWorkoutType,

	program,
	rpe,
	allItems,
	allNames,
	loading
})