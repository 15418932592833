import { CURRENT_NUTRIENT, CLEAR_NUTRIENT } from '../../actions/nutrient';

export default function nutrientCurrent(state = [], action) {
	switch (action.type) {
		case CURRENT_NUTRIENT:
			return action.data;
		case CLEAR_NUTRIENT:
			return [];
		default:
			return state;
	}
}