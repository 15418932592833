import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
	handleGrabMuscleNames,
	handleGrabMuscleColumnOptions,
	handleGrabMuscleColumns,
	handleCurrentMuscles,
	handleGrabOneMuscle,
	handleGrabMuscleColumnInfo,
	handleGrabMuscleItems
} from '../actions/muscle';


class MuscleHome extends Component {

	constructor(props) {
		super(props);
		this.state = {

		};
		this.handleClick = this.handleClick.bind(this)
	}


	componentDidMount() {
		if (Object.keys(this.props.muscleNames).length === 0) {
			this.props.handleGrabMuscleNames();
		}
		if (Object.keys(this.props.muscleColumns).length === 0) {
			this.props.handleGrabMuscleColumns();
		}
		if (Object.keys(this.props.muscleColumnOptions).length === 0) {
			this.props.handleGrabMuscleColumnOptions();
		}
		if (Object.keys(this.props.muscleColumnInfo).length === 0) {
			this.props.handleGrabMuscleColumnInfo();
		}
		if (Object.keys(this.props.muscleItems).length === 0) {
			this.props.handleGrabMuscleItems();
		}
	}

	handleClick(name) {
		if (!Object.keys(this.props.muscle).find(key => key === this.props.muscleNames[name])) {
			this.props.handleGrabOneMuscle(this.props.muscleNames[name]);
		} else {
			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		}

		let temp = this.props.muscleCurrent;
		if (this.props.muscleCurrent.find(key => key === this.props.muscleNames[name])) {
			temp.splice(temp.indexOf(this.props.muscleNames[name]), 1);

			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		} else {
			temp.push(this.props.muscleNames[name]);
		}
		this.props.handleCurrentMuscles(temp);

	}

	render() {
		return (
			<div className="App">
				<Container>

					<Row>
						<Col sm={3}>
							<ListGroup>
								{Object.keys(this.props.muscleNames).map((name) => (
									<ListGroup.Item action onClick={(e) => this.handleClick(name, e)} key={name}>
										{name}
									</ListGroup.Item>
								))}
							</ListGroup>
						</Col>

						<Col sm={9}>
							<Table striped bordered hover>
								<thead>
									<tr>
										{Object.keys(this.props.muscleColumns).map((column) => (
											<th key={column}>{column}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{this.props.muscleCurrent.map((muscleKey) => (
										<tr key={muscleKey}>
											{Object.values(this.props.muscleColumns).map((columnKey) => (
												<td key={columnKey}>
													{((this.props.muscle[muscleKey][columnKey]) ?
														this.props.muscle[muscleKey][columnKey] :
														"")}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</Table>
						</Col>
					</Row>

				</Container>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userStatus: state.userStatus,
		userName: state.userName,
		muscle: state.muscle,
		muscleNames: state.muscleNames,
		muscleItems: state.muscleItems,
		muscleCurrent: state.muscleCurrent,
		muscleColumns: state.muscleColumns,
		muscleColumnOptions: state.muscleColumnOptions,
		muscleColumnInfo: state.muscleColumnInfo
	}
}

export default connect(mapStateToProps, {
	handleGrabMuscleNames,
	handleGrabMuscleColumnOptions,
	handleGrabMuscleColumns,
	handleCurrentMuscles,
	handleGrabOneMuscle,
	handleGrabMuscleColumnInfo,
	handleGrabMuscleItems
})(MuscleHome);