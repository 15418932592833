import React, {Component} from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav';
import { handleUserStatus } from '../actions/user';
import { handleToggleInfo, handleChangeLoading } from '../actions/other';
import { BsInfoCircle } from 'react-icons/bs'
import {
	handleGrabExerciseNames,
	handleGrabExerciseColumnOptions,
	handleGrabExerciseColumns,
	handleGrabExerciseColumnInfo,
	handleGrabExerciseItems
} from '../actions/exercise';
import {
	handleGrabAllMuscle,
	handleGrabMuscleNames,
	handleGrabMuscleColumnOptions,
	handleGrabMuscleColumns,
	handleGrabMuscleColumnInfo,
	handleGrabMuscleItems
} from '../actions/muscle';
import {
	handleGrabNutrientNames,
	handleGrabNutrientColumnOptions,
	handleGrabNutrientColumns,
	handleGrabNutrientColumnInfo,
	handleGrabNutrientItems
} from '../actions/nutrient';
import {
	handleGrabFoodNames,
	handleGrabFoodColumnOptions,
	handleGrabFoodColumns,
	handleGrabFoodColumnInfo
} from '../actions/food';
import {
	handleGrabWorkoutType,
	handleGrabSetType
} from '../actions/anaerobic';
import {
	handleGrabRPE
} from '../actions/rpe';
import {
	handleGrabProgram
} from '../actions/program';


class Header extends Component {

	constructor(props){
		super(props);
		this.state = {
			activeKey: "/" + this.props.location.pathname.split("/")[1],
			infoPopupText: "",
			infoPopupTitle: ""
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (this.props.userStatus) {

			let dict = {
				"exerciseNames": this.props.handleGrabExerciseNames,
				"exerciseItems": this.props.handleGrabExerciseItems,
				"exerciseColumns": this.props.handleGrabExerciseColumns,
				"exerciseColumnOptions": this.props.handleGrabExerciseColumnOptions,
				"exerciseColumnInfo": this.props.handleGrabExerciseColumnInfo,

				"foodNames": this.props.handleGrabFoodNames,
				"foodColumns": this.props.handleGrabFoodColumns,
				"foodColumnOptions": this.props.handleGrabFoodColumnOptions,
				"foodColumnInfo": this.props.handleGrabFoodColumnInfo,

				"allMuscle": this.props.handleGrabAllMuscle,
				"muscleNames": this.props.handleGrabMuscleNames,
				"muscleItems": this.props.handleGrabMuscleItems,
				"muscleColumns": this.props.handleGrabMuscleColumns,
				"muscleColumnOptions": this.props.handleGrabMuscleColumnOptions,
				"muscleColumnInfo": this.props.handleGrabMuscleColumnInfo,

				"nutrientNames": this.props.handleGrabNutrientNames,
				"nutrientItems": this.props.handleGrabNutrientItems,
				"nutrientColumns": this.props.handleGrabNutrientColumns,
				"nutrientColumnOptions": this.props.handleGrabNutrientColumnOptions,
				"nutrientColumnInfo": this.props.handleGrabNutrientColumnInfo,

				"anaerobicWorkoutType": this.props.handleGrabWorkoutType,
				"anaerobicSetType": this.props.handleGrabSetType,
				"rpe": this.props.handleGrabRPE,
				"program": this.props.handleGrabProgram
			}

			let time = 0;

			for (let i = 0; i < Object.values(this.props.check).length; i++) {
				if (i === 1) {
					this.props.handleChangeLoading(true);
				}
				if (!Object.values(this.props.check)[i]) {
					time += 1;
					this.loadData(dict[Object.keys(this.props.check)[i]], time);
				}
				if (i === Object.values(this.props.check).length - 1) {
					setTimeout(() => {
						this.props.handleChangeLoading(false);
					}, 500 * time);
				}
			}


			document.addEventListener('mouseover', (e) => {
				if (this.props.toggleInfo) {
					if (!!e.target.lastChild && !!e.target.firstChild) {
						if (!!e.target.lastChild.data && e.target.lastChild.data === e.target.firstChild.data) {
							if (e.target.innerHTML !== this.state.infoPopupTitle) {
								let tempKey = "";
								let tempText = "";

								tempKey = ((!!this.props.allNames[e.target.innerHTML]) ? this.props.allNames[e.target.innerHTML] : tempKey);

								tempText = ((!!this.props.allItems[tempKey]) ? this.props.allItems[tempKey].info : tempText);

								if (tempText !== "") {
									this.setState({ ...this.state, infoPopupTitle: e.target.innerHTML, infoPopupText: tempText });
                                }
							}

						}
					}
				}
			})
		}
	}


	loadData(exe, i) {
		setTimeout(function () {
			exe();
		}, 500 * i);
	}

	handleClick() {
		this.props.handleUserStatus(false);
	}

	handleSelect(selectedKey) {
		this.setState({...this.state, activeKey: selectedKey})
    }

	render(){
		return(
			<div className="App">
				{((this.props.toggleInfo) ?
					<div style={{ position: 'fixed', right: '10px', top: '100px', borderStyle: 'solid', margin: '10px', padding: '10px', width: '350px', backgroundColor: 'white' }}>
						<h4>{this.state.infoPopupTitle}</h4>
						{((Array.isArray(this.state.infoPopupText)) ?
							this.state.infoPopupText.map((text) => (
								<div key={"div" + text}>
									<p>{text.replace(";", ".")}</p>
								</div>)) :
							<p>{this.state.infoPopupText.replace(";", ".")}</p>)}
					</div>
					: <></>)}
				{((!this.props.loading) ?
					<Navbar bg="dark" variant="dark" expand="lg" sticky="top">
						<Navbar.Brand href="/" style={{ color: 'white' }}><img src='/images/title/logo.png' alt='logo' style={{ height: '40px', padding: '0', margin: '0' }} />
						</Navbar.Brand>
						<Nav justify className="mr-auto" style={{ width: '55%' }} activeKey={this.state.activeKey} onSelect={this.handleSelect}>
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/">Dashboard</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/workouts">Workouts</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/recipes">Recipes</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/assessment">Assessment</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/muscles">Muscles</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/nutrient">Nutrient</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/personalinfo">Personal Info</Nav.Link></Nav.Item> : <></>}
							{(this.props.userStatus) ? <Nav.Item className="mr-2 ml-2 border-bottom border-success"><Nav.Link href="/admin">Admin</Nav.Link></Nav.Item> : <></>}
						</Nav>
						<Nav className="float-right">
							{(this.props.userStatus) ? <BsInfoCircle onClick={this.props.handleToggleInfo} color={((this.props.toggleInfo) ? "white" : "grey")} size="2em" style={{ margin: '5px' }} /> : <></>}
							<Nav.Link href="/login" onClick={this.handleClick}>{(this.props.userStatus) ? 'Log Out' : 'Log In'}</Nav.Link>
						</Nav>
					</Navbar>
					: <> </>)}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return{
		userStatus: state.userStatus,
		toggleInfo: state.allItems.toggleInfo,
		allItems: state.allItems.allItems,
		allNames: state.allNames.allNames,
		muscle: state.muscle,
		check: {
			"exerciseNames": Object.keys(state.exerciseNames).length > 0,
			"exerciseItems": Object.keys(state.exerciseItems).length > 0,
			"exerciseColumns": Object.keys(state.exerciseColumns).length > 0,
			"exerciseColumnOptions": Object.keys(state.exerciseColumnOptions).length > 0,
			"exerciseColumnInfo": Object.keys(state.exerciseColumnInfo).length > 0,

			"foodNames": Object.keys(state.foodNames).length > 0,
			"foodColumns": Object.keys(state.foodColumns).length > 0,
			"foodColumnOptions": Object.keys(state.foodColumnOptions).length > 0,
			"foodColumnInfo": Object.keys(state.foodColumnInfo).length > 0,

			"allMuscle": Object.keys(state.muscle).length > 0,
			"muscleNames": Object.keys(state.muscleNames).length > 0,
			"muscleItems": Object.keys(state.muscleItems).length > 0,
			"muscleColumns": Object.keys(state.muscleColumns).length > 0,
			"muscleColumnOptions": Object.keys(state.muscleColumnOptions).length > 0,
			"muscleColumnInfo": Object.keys(state.muscleColumnInfo).length > 0,

			"nutrientNames": Object.keys(state.nutrientNames).length > 0,
			"nutrientItems": Object.keys(state.nutrientItems).length > 0,
			"nutrientColumns": Object.keys(state.nutrientColumns).length > 0,
			"nutrientColumnOptions": Object.keys(state.nutrientColumnOptions).length > 0,
			"nutrientColumnInfo": Object.keys(state.nutrientColumnInfo).length > 0,

			"anaerobicWorkoutType": Object.keys(state.anaerobicWorkoutType).length > 0,
			"anaerobicSetType": Object.keys(state.anaerobicSetType).length > 0,
			"rpe": Object.keys(state.rpe).length > 0,
			"program": Object.keys(state.program.phase).length > 0

		}
	}
}

export default withRouter(connect(mapStateToProps, {
	handleUserStatus,
	handleToggleInfo,
	handleChangeLoading,

	handleGrabExerciseNames,
	handleGrabExerciseColumnOptions,
	handleGrabExerciseColumns,
	handleGrabExerciseColumnInfo,
	handleGrabExerciseItems,

	handleGrabAllMuscle,
	handleGrabMuscleNames,
	handleGrabMuscleColumnOptions,
	handleGrabMuscleColumns,
	handleGrabMuscleColumnInfo,
	handleGrabMuscleItems,

	handleGrabFoodNames,
	handleGrabFoodColumnOptions,
	handleGrabFoodColumns,
	handleGrabFoodColumnInfo,

	handleGrabNutrientNames,
	handleGrabNutrientColumnOptions,
	handleGrabNutrientColumns,
	handleGrabNutrientColumnInfo,
	handleGrabNutrientItems,

	handleGrabWorkoutType,
	handleGrabSetType,
	handleGrabRPE,
	handleGrabProgram
})(Header));