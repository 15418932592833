import { GRAB_MUSCLE_NAMES, GRAB_MUSCLE_COLUMNS, GRAB_MUSCLE_COLUMN_OPTIONS } from '../actions/muscle';
import { GRAB_NUTRIENT_NAMES, GRAB_NUTRIENT_COLUMNS, GRAB_NUTRIENT_COLUMN_OPTIONS } from '../actions/nutrient';
import { GRAB_EXERCISE_NAMES, GRAB_EXERCISE_COLUMNS, GRAB_EXERCISE_COLUMN_OPTIONS } from '../actions/exercise';
import { GRAB_FOOD_NAMES, GRAB_FOOD_COLUMNS, GRAB_FOOD_COLUMN_OPTIONS } from '../actions/food';

export default function allItems(state = {
	muscleNames: false, muscleColumns: false, muscleColumnOptions: false,
	nutrientNames: false, nutrientColumns: false, nutrientColumnOptions: false,
	exerciseNames: false, exerciseColumns: false, exerciseColumnOptions: false,
	foodNames: false, foodColumns: false, foodColumnOptions: false, allNames: {}
}, action) {
	switch (action.type) {
		case GRAB_MUSCLE_NAMES:
			if (!state.muscleNames) {
				return { ...state, muscleNames: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
            }
		case GRAB_MUSCLE_COLUMNS:
			if (!state.muscleColumns) {
				return { ...state, muscleColumns: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_MUSCLE_COLUMN_OPTIONS:
			if (!state.muscleColumnOptions) {
				let temp = {};
				let tempStr = "";
				let tempStr1 = "";
				for (let i = 0; i < Object.keys(action.data).length; i++) {
					tempStr = Object.keys(action.data)[i];
					for (let j = 0; j < Object.keys(action.data[tempStr]).length; j++) {
						tempStr1 = Object.keys(action.data[tempStr])[j];
						temp[tempStr1] = action.data[tempStr][tempStr1]
                    }
                }
				return { ...state, muscleColumnOptions: true, allNames: { ...state.allNames, ...temp } };
			} else {
				return state;
			}
		case GRAB_NUTRIENT_NAMES:
			if (!state.nutrientNames) {
				return { ...state, nutrientNames: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_NUTRIENT_COLUMNS:
			if (!state.nutrientColumns) {
				return { ...state, nutrientColumns: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_NUTRIENT_COLUMN_OPTIONS:
			if (!state.nutrientColumnOptions) {
				let temp = {};
				let tempStr = "";
				let tempStr1 = "";
				for (let i = 0; i < Object.keys(action.data).length; i++) {
					tempStr = Object.keys(action.data)[i];
					for (let j = 0; j < Object.keys(action.data[tempStr]).length; j++) {
						tempStr1 = Object.keys(action.data[tempStr])[j];
						temp[tempStr1] = action.data[tempStr][tempStr1]
					}
				}
				return { ...state, nutrientColumnOptions: true, allNames: { ...state.allNames, ...temp } };
			} else {
				return state;
			}
		case GRAB_EXERCISE_NAMES:
			if (!state.exerciseNames) {
				return { ...state, exerciseNames: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_EXERCISE_COLUMNS:
			if (!state.exerciseColumns) {
				return { ...state, exerciseColumns: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_EXERCISE_COLUMN_OPTIONS:
			if (!state.exerciseColumnOptions) {
				let temp = {};
				let tempStr = "";
				let tempStr1 = "";
				for (let i = 0; i < Object.keys(action.data).length; i++) {
					tempStr = Object.keys(action.data)[i];
					for (let j = 0; j < Object.keys(action.data[tempStr]).length; j++) {
						tempStr1 = Object.keys(action.data[tempStr])[j];
						temp[tempStr1] = action.data[tempStr][tempStr1]
					}
				}
				return { ...state, exerciseColumnOptions: true, allNames: { ...state.allNames, ...temp } };
			} else {
				return state;
			}
		case GRAB_FOOD_NAMES:
			if (!state.foodNames) {
				return { ...state, foodNames: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_FOOD_COLUMNS:
			if (!state.foodColumns) {
				return { ...state, foodColumns: true, allNames: { ...state.allNames, ...action.data } };
			} else {
				return state;
			}
		case GRAB_FOOD_COLUMN_OPTIONS:
			if (!state.foodColumnOptions) {
				let temp = {};
				let tempStr = "";
				let tempStr1 = "";
				for (let i = 0; i < Object.keys(action.data).length; i++) {
					tempStr = Object.keys(action.data)[i];
					for (let j = 0; j < Object.keys(action.data[tempStr]).length; j++) {
						tempStr1 = Object.keys(action.data[tempStr])[j];
						temp[tempStr1] = action.data[tempStr][tempStr1]
					}
				}
				return { ...state, foodColumnOptions: true, allNames: { ...state.allNames, ...temp } };
			} else {
				return state;
			}
		default:
			return state;
	}
}