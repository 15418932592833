import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_ONE_NUTRIENT = 'GRAB_ONE_NUTRIENT';
export const CLEAR_NUTRIENT = "CLEAR_NUTRIENT";
export const CURRENT_NUTRIENT = "CURRENT_NUTRIENT";
export const GRAB_NUTRIENT_NAMES = 'GRAB_NUTRIENT_NAMES';
export const GRAB_NUTRIENT_COLUMNS = 'GRAB_NUTRIENT_COLUMNS';
export const GRAB_NUTRIENT_COLUMN_OPTIONS = 'GRAB_NUTRIENT_COLUMN_OPTIONS';
export const GRAB_NUTRIENT_COLUMN_INFO = 'GRAB_NUTRIENT_COLUMN_INFO';
export const GRAB_NUTRIENT_ITEMS = 'GRAB_NUTRIENT_ITEMS';

export const handleGrabNutrientNames = () => async dispatch => {
	console.log('starting grab nutrient names');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/food/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_NUTRIENT_NAMES,
			data: snapshot.val()
		})
	});
}

export const handleGrabNutrientColumnOptions = () => async dispatch => {
	console.log('starting grab nutrient column options');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/nutrient/columnOptions")).then((snapshot) => {
		dispatch({
			type: GRAB_NUTRIENT_COLUMN_OPTIONS,
			data: snapshot.val()
		})
	});
}

export const handleGrabNutrientColumns = () => async dispatch => {
	console.log('starting grab nutrient columns');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/nutrient/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_NUTRIENT_COLUMNS,
			data: snapshot.val()
		})
	});
}

export const handleCurrentNutrients = (keys) => async dispatch => {
	//keys should be in an array
	dispatch({
		type: CURRENT_NUTRIENT,
		data: keys
	})
}

export const handleGrabOneNutrient = (key) => async dispatch => {
	console.log('starting grab one nutrient');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "nutrient/" + key)).then((snapshot) => {
		console.log(snapshot);
		dispatch({
			type: GRAB_ONE_NUTRIENT,
			key,
			data: snapshot.val()
		})
	});
}

export const handleGrabNutrientColumnInfo = () => async dispatch => {
	console.log('starting grab nutrient column info');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/nutrient/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_NUTRIENT_COLUMN_INFO,
			data: snapshot.val()
		})
	});
}

export const handleGrabNutrientItems = () => async dispatch => {
	console.log('starting grab nutrient items');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/nutrient/items")).then((snapshot) => {
		dispatch({
			type: GRAB_NUTRIENT_ITEMS,
			data: snapshot.val()
		})
	});
}

export function handleClearNutrient() {
	return {
		type: CLEAR_NUTRIENT
	}
}
