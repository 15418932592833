import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Multiselect from 'multiselect-react-dropdown';
import {
	handleCurrentExercises,
	handleGrabOneExercise
} from '../actions/exercise';


class ExerciseHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			formOrder: [
				"Exercise", "Goal", "Workout Timing", "Prime Mover", "All Muscles", "Kinetic Chain",
				"Kinetic Circuits", "Grip", "Movement Body Part", "Movement Pattern", "Movement Plane",
				"Loading Pattern", "Muscle Contraction Type", "Adaptation", "Energy System", "Intensity",
				"Speed", "Stability", "Motor Rehearsal", "Emphasis", "The Inner Unit", "The Outer Unit",
				"Joint Inclusion", "Pelvic Positioning", "Postural Distortions"
			],
			selectedExercise: "",
			preselectedValues: {},
			selectedValues: {},
			currentTooltip: "",
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.grabOptions = this.grabOptions.bind(this);

	}


	async componentDidMount() {

	}

	handleClick(name) {
		if (!Object.keys(this.props.exercises).find(key => key === this.props.exerciseNames[name])) {
			this.props.handleGrabOneExercise(this.props.exerciseNames[name]);
		} else {
			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		}

		let temp = this.props.exerciseCurrent;
		if (this.props.exerciseCurrent.find(key => key === this.props.exerciseNames[name])) {
			temp.splice(temp.indexOf(this.props.exerciseNames[name]), 1);

			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		} else {
			temp.push(this.props.exerciseNames[name])
		}
		this.props.handleCurrentExercises(temp);

	}

	handleSubmit() {

	}

	grabOptions(columnName) {
		let temp = [];
		let options = {};
		let columnKey = "";
		let laymenLoc = "IZDMTAPVEUOGWOYDVQMY";

		if (columnName === "Prime Mover" || columnName === "All Muscles") {
			options = this.props.muscleNames;

			for (let i = 0; i < Object.keys(options).length; i++) {

				temp.push({
					name: Object.keys(options)[i],
					id: Object.values(options)[i],
					group: this.props.muscle[Object.values(options)[i]][laymenLoc]
				});
			}
		} else if (columnName !== "Exercise") {
			columnKey = this.props.exerciseColumns[columnName];
			options = this.props.exerciseColumnOptions[columnKey];

			for (let i = 0; i < Object.keys(options).length; i++) {
				temp.push({
					name: Object.keys(options)[i],
					id: Object.values(options)[i],
					group: columnName
				});
			}
        }

		return temp;

	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<ListGroup>
							{Object.keys(this.props.exerciseNames).map((name) => (
								<ListGroup.Item action onClick={(e) => this.handleClick(name, e)} key={name}>
									{name}
								</ListGroup.Item>
							))}
						</ListGroup>
					</Col>
					<Col>
						{this.state.formOrder.map((column) => (
							<div className="wrapper" key={column}>
								<div style={{ textAlign: 'left', position: 'relative' }} key={"divother" + column}>
									<h5 key={"header" + column}>
										{column}
									</h5>
								</div>
								<Multiselect
									key={"multiselect" + column}
									options={this.grabOptions(column)}
									displayValue="name"
									groupBy="group"

									selectedValues={[]}
									disablePreSelectedValues={false}

									id={"multiselectid" + column}
									selectionLimit={-1}
									isObject={true}
									emptyRecordMsg={"No Options Found"}
									avoidHighlightFirstOption={true}
								/>

							</div>
						))}
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		userStatus: state.userStatus,
		userName: state.userName,
		exercises: state.exercises,
		exerciseNames: state.exerciseNames,
		exerciseItems: state.exerciseItems,
		exerciseCurrent: state.exerciseCurrent,
		exerciseColumns: state.exerciseColumns,
		exerciseColumnOptions: state.exerciseColumnOptions,
		exerciseColumnInfo: state.exerciseColumnInfo,
		muscleNames: state.muscleNames,
		muscleItems: state.muscleItems,
		muscleColumns: state.muscleColumns,
		muscleColumnOptions: state.muscleColumnOptions,
		muscleColumnInfo: state.muscleColumnInfo,
		muscle: state.muscle,
		anaerobicSetType: state.anaerobicSetType,
		anaerobicWorkoutType: state.anaerobicWorkoutType,
		rpe: state.rpe,
		program: state.program,
		allItems: state.allItems
	}
}

export default connect(mapStateToProps, {
	handleCurrentExercises,
	handleGrabOneExercise
})(ExerciseHome);