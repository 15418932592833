import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_PROGRAM = 'GRAB_PROGRAM';

export const handleGrabProgram = () => async dispatch => {
	console.log('starting grab program');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "program")).then((snapshot) => {
		dispatch({
			type: GRAB_PROGRAM,
			data: snapshot.val()
		})
	});
}