import { CURRENT_FOOD, CLEAR_FOOD } from '../../actions/food';

export default function foodCurrent(state = [], action) {
	switch (action.type) {
		case CURRENT_FOOD:
			return action.data;
		case CLEAR_FOOD:
			return [];
		default:
			return state;
	}
}