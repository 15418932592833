import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_WORKOUT_TYPE = 'GRAB_WORKOUT_TYPE';
export const GRAB_SET_TYPE = "GRAB_SET_TYPE";

export const handleGrabWorkoutType = () => async dispatch => {
	console.log('starting grab workout types');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "anaerobic/workoutType")).then((snapshot) => {
		dispatch({
			type: GRAB_WORKOUT_TYPE,
			data: snapshot.val()
		})
	});
}

export const handleGrabSetType = () => async dispatch => {
	console.log('starting grab set types');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "anaerobic/setType")).then((snapshot) => {
		dispatch({
			type: GRAB_SET_TYPE,
			data: snapshot.val()
		})
	});
}