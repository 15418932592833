import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {createStore} from 'redux';

import middleware from './middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from './reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';




const persistConfig = {
  key: 'thepathtohealth',
  storage,
  stateReconciler: autoMergeLevel2,
    //blacklist: ['exerciseNames', 'exerciseItems', 'exerciseColumns', 'exerciseColumnOptions', 'exerciseColumnInfo',
    //            'foodNames', 'foodColumns', 'foodColumnOptions', 'foodColumnInfo',
    //            'muscle', 'muscleNames', 'muscleItems', 'muscleColumns', 'muscleColumnOptions', 'muscleColumnInfo',
    //            'nutrientNames', 'nutrientItems', 'nutrientColumns', 'nutrientColumnOptions', 'nutrientColumnInfo',
    //            'program', 'rpe', 'anaerobicSetType', 'anaerobicWorkoutType', 'allItems', 'allNames']
    blacklist: ['exercises', 'exerciseCurrent', 'food', 'foodCurrent', 'muscleCurrent', 'nutrient', 'nutrientCurrent']
  //whitelist: ['userStatus', 'userName']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools(middleware));
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <App />,
    </PersistGate>
    </Provider>,
   document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
