import {handleUserStatus} from './actions/user';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


export const firebaseConfig = {
    apiKey: "AIzaSyDP6qK3f-XCKhPxoqm05NAapJL9HCmRG6w",
    authDomain: "the-path-to-health.firebaseapp.com",
    databaseURL: "https://the-path-to-health-default-rtdb.firebaseio.com",
    projectId: "the-path-to-health",
    storageBucket: "the-path-to-health.appspot.com",
    messagingSenderId: "1010786781944",
    appId: "1:1010786781944:web:01d23311acdd6bdc05090d",
    measurementId: "G-3YFS1NTV45"
};

const app = initializeApp(firebaseConfig);

export const db = getDatabase(app);

let temp = getAuth(app).currentUser;


onAuthStateChanged(getAuth(), (user) => {
  if (user) {
    temp = user; 
    handleUserStatus(true);
  } else {
      temp = null;
      handleUserStatus(false);
  }
});

export const userId = temp;
