import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
	handleGrabFoodNames,
	handleGrabFoodColumnOptions,
	handleGrabFoodColumns,
	handleCurrentFood,
	handleGrabOneFood,
	handleGrabFoodColumnInfo
} from '../actions/food';


class FoodHome extends Component {

	constructor(props) {
		super(props);
		this.state = {
			
		};
		this.handleClick = this.handleClick.bind(this)
	}


	componentDidMount() {
		if (Object.keys(this.props.foodNames).length === 0) {
			this.props.handleGrabFoodNames();
		}
		if (Object.keys(this.props.foodColumns).length === 0) {
			this.props.handleGrabFoodColumns();
		}
		if (Object.keys(this.props.foodColumnOptions).length === 0) {
			this.props.handleGrabFoodColumnOptions();
		}
		if (Object.keys(this.props.foodColumnInfo).length === 0) {
			this.props.handleGrabFoodColumnInfo();
		}
	}

	handleClick(name) {
		if (!Object.keys(this.props.food).find(key => key === this.props.foodNames[name])) {
			this.props.handleGrabOneFood(this.props.foodNames[name]);
		} else {
			setTimeout(() => {
				this.forceUpdate();
			}, 500);
        }

		let temp = this.props.foodCurrent;
		if (this.props.foodCurrent.find(key => key === this.props.foodNames[name])) {
			temp.splice(temp.indexOf(this.props.foodNames[name]), 1);

			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		} else {
			temp.push(this.props.foodNames[name])
        }
		this.props.handleCurrentFood(temp);
		
	}

	render() {
		return (
			<div className="App">
				<Container>

					<Row>
					<Col sm={3}>
					<ListGroup>
						{Object.keys(this.props.foodNames).map((name) => (
							<ListGroup.Item action onClick={(e) => this.handleClick(name, e)} key={name}>
								{name}
							</ListGroup.Item>
						))}
					</ListGroup>
					</Col>

					<Col sm={9}>
					<Table striped bordered hover>
						<thead>
							<tr>
								{Object.keys(this.props.foodColumns).map((column) => (
									<th key={column}>{column}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{this.props.foodCurrent.map((foodKey) => (
								<tr key={foodKey}>
									{Object.values(this.props.foodColumns).map((columnKey) => (
										<td key={columnKey}>
											{((this.props.food[foodKey][columnKey]) ?
												this.props.food[foodKey][columnKey] :
												"")}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</Table>
						</Col>
						</Row>

				</Container>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userStatus: state.userStatus,
		userName: state.userName,
		food: state.food,
		foodNames: state.foodNames,
		foodItems: state.foodItems,
		foodCurrent: state.foodCurrent,
		foodColumns: state.foodColumns,
		foodColumnOptions: state.foodColumnOptions,
		foodColumnInfo: state.foodColumnInfo
	}
}

export default connect(mapStateToProps, {
	handleGrabFoodNames,
	handleGrabFoodColumnOptions,
	handleGrabFoodColumns,
	handleCurrentFood,
	handleGrabOneFood,
	handleGrabFoodColumnInfo
})(FoodHome);