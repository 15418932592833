import { GRAB_SET_TYPE } from '../../actions/anaerobic';


export default function setType(state = {}, action) {
	switch (action.type) {
		case GRAB_SET_TYPE:
			return action.data;
		default:
			return state;
	}
}