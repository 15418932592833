import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_ONE_MUSCLE = 'GRAB_ONE_MUSCLE';
export const GRAB_ALL_MUSCLE = 'GRAB_ALL_MUSCLE';
export const CLEAR_MUSCLE = "CLEAR_MUSCLE";
export const CURRENT_MUSCLE = "CURRENT_MUSCLE";
export const GRAB_MUSCLE_NAMES = 'GRAB_MUSCLE_NAMES';
export const GRAB_MUSCLE_COLUMNS = 'GRAB_MUSCLE_COLUMNS';
export const GRAB_MUSCLE_COLUMN_OPTIONS = 'GRAB_MUSCLE_COLUMN_OPTIONS';
export const GRAB_MUSCLE_COLUMN_INFO = 'GRAB_MUSCLE_COLUMN_INFO';
export const GRAB_MUSCLE_ITEMS = 'GRAB_MUSCLE_ITEMS';

export const handleGrabMuscleNames = () => async dispatch => {
	console.log('starting grab muscle names');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/muscle/names")).then((snapshot) => {
		dispatch({
			type: GRAB_MUSCLE_NAMES,
			data: snapshot.val()
		})
	});
}

export const handleGrabMuscleColumnOptions = () => async dispatch => {
	console.log('starting grab muscle column options');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/muscle/columnOptions")).then((snapshot) => {
		dispatch({
			type: GRAB_MUSCLE_COLUMN_OPTIONS,
			data: snapshot.val()
		})
	});
}

export const handleGrabMuscleColumns = () => async dispatch => {
	console.log('starting grab muscle columns');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/muscle/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_MUSCLE_COLUMNS,
			data: snapshot.val()
		})
	});
}

export const handleCurrentMuscles = (keys) => async dispatch => {
	//keys should be in an array
	dispatch({
		type: CURRENT_MUSCLE,
		data: keys
	})
}

export const handleGrabOneMuscle = (key) => async dispatch => {
	console.log('starting grab one muscle');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "muscle/" + key)).then((snapshot) => {
		console.log(snapshot);
		dispatch({
			type: GRAB_ONE_MUSCLE,
			key,
			data: snapshot.val()
		})
	});
}

export const handleGrabAllMuscle = () => async dispatch => {
	console.log('starting grab all muscle');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "muscle")).then((snapshot) => {
		dispatch({
			type: GRAB_ALL_MUSCLE,
			data: snapshot.val()
		})
	});
}

export const handleGrabMuscleColumnInfo = () => async dispatch => {
	console.log('starting grab muscle column info');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/muscle/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_MUSCLE_COLUMN_INFO,
			data: snapshot.val()
		})
	});
}

export const handleGrabMuscleItems = () => async dispatch => {
	console.log('starting grab muscle items');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/muscle/items")).then((snapshot) => {
		dispatch({
			type: GRAB_MUSCLE_ITEMS,
			data: snapshot.val()
		})
	});
}

export function handleClearMuscle() {
	return {
		type: CLEAR_MUSCLE
	}
}
