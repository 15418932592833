import { firebaseConfig } from '../firebaseAPI';
import { ref, get, child, getDatabase } from "firebase/database";
import { initializeApp } from 'firebase/app';
export const GRAB_ONE_FOOD = 'GRAB_ONE_FOOD';
export const CLEAR_FOOD = "CLEAR_FOOD";
export const CURRENT_FOOD = "CURRENT_FOOD";
export const GRAB_FOOD_NAMES = 'GRAB_FOOD_NAMES';
export const GRAB_FOOD_COLUMNS = 'GRAB_FOOD_COLUMNS';
export const GRAB_FOOD_COLUMN_OPTIONS = 'GRAB_FOOD_COLUMN_OPTIONS';
export const GRAB_FOOD_COLUMN_INFO = 'GRAB_FOOD_COLUMN_INFO';

export const handleGrabFoodNames = () => async dispatch => {
	console.log('starting grab food names');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/food/names")).then((snapshot) => {
		dispatch({
			type: GRAB_FOOD_NAMES,
			data: snapshot.val()
		})
	});
}

export const handleGrabFoodColumnOptions = () => async dispatch => {
	console.log('starting grab food column options');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/food/columnOptions")).then((snapshot) => {
		dispatch({
			type: GRAB_FOOD_COLUMN_OPTIONS,
			data: snapshot.val()
		})
	});
}

export const handleGrabFoodColumns = () => async dispatch => {
	console.log('starting grab food columns');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "index/food/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_FOOD_COLUMNS,
			data: snapshot.val()
		})
	});
}

export const handleCurrentFood = (keys) => async dispatch => {
	//keys should be in an array
	dispatch({
		type: CURRENT_FOOD,
		data: keys
	})
}

export const handleGrabOneFood = (key) => async dispatch => {
	console.log('starting grab one food');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "food/" + key)).then((snapshot) => {
		console.log(snapshot);
		dispatch({
			type: GRAB_ONE_FOOD,
			key,
			data: snapshot.val()
		})
	});
}

export const handleGrabFoodColumnInfo = () => async dispatch => {
	console.log('starting grab food column info');
	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, "info/food/columns")).then((snapshot) => {
		dispatch({
			type: GRAB_FOOD_COLUMN_INFO,
			data: snapshot.val()
		})
	});
}

export function handleClearFood() {
	return {
		type: CLEAR_FOOD
	}
}
