import { GRAB_ONE_MUSCLE, CLEAR_MUSCLE, GRAB_ALL_MUSCLE } from '../../actions/muscle';


export default function muscle(state = {}, action) {
	switch (action.type) {
		case GRAB_ONE_MUSCLE:
			return { ...state, [action.key]: action.data };
		case GRAB_ALL_MUSCLE:
			return action.data;
		case CLEAR_MUSCLE:
			return {};
		default:
			return state;
	}
}