import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';


class Search extends Component {



	render() {
		return (
			<div className="App">
					<Container>
						<h1>Webpage Currently in Progress</h1>
						<p>
							This feature of the site is currently under development. Clicks on currently developing features are recorded, with the most clicked features being highest priority.
					</p>
					</Container>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {

	}
}

export default connect(mapStateToProps)(Search);