import { CURRENT_MUSCLE, CLEAR_MUSCLE } from '../../actions/muscle';

export default function muscleCurrent(state = [], action) {
	switch (action.type) {
		case CURRENT_MUSCLE:
			return action.data;
		case CLEAR_MUSCLE:
			return [];
		default:
			return state;
	}
}