import { GRAB_PROGRAM } from '../actions/program';


export default function program(state = { energy: { byGoal: {}, bySystem: {} }, phase: {}, warmup: {} }, action) {
	switch (action.type) {
		case GRAB_PROGRAM:
			return action.data;
		default:
			return state;
	}
}