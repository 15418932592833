import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
	handleGrabNutrientNames,
	handleGrabNutrientColumnOptions,
	handleGrabNutrientColumns,
	handleCurrentNutrients,
	handleGrabOneNutrient,
	handleGrabNutrientColumnInfo,
	handleGrabNutrientItems
} from '../actions/nutrient';


class NutrientHome extends Component {

	constructor(props) {
		super(props);
		this.state = {

		};
		this.handleClick = this.handleClick.bind(this)
	}


	componentDidMount() {
		if (Object.keys(this.props.nutrientNames).length === 0) {
			this.props.handleGrabNutrientNames();
		}
		if (Object.keys(this.props.nutrientColumns).length === 0) {
			this.props.handleGrabNutrientColumns();
		}
		if (Object.keys(this.props.nutrientColumnOptions).length === 0) {
			this.props.handleGrabNutrientColumnOptions();
		}
		if (Object.keys(this.props.nutrientColumnInfo).length === 0) {
			this.props.handleGrabNutrientColumnInfo();
		}
		if (Object.keys(this.props.nutrientItems).length === 0) {
			this.props.handleGrabNutrientItems();
		}
	}

	handleClick(name) {
		if (!Object.keys(this.props.nutrient).find(key => key === this.props.nutrientNames[name])) {
			this.props.handleGrabOneNutrient(this.props.nutrientNames[name]);
		} else {
			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		}

		let temp = this.props.nutrientCurrent;
		if (this.props.nutrientCurrent.find(key => key === this.props.nutrientNames[name])) {
			temp.splice(temp.indexOf(this.props.nutrientNames[name]), 1);

			setTimeout(() => {
				this.forceUpdate();
			}, 500);
		} else {
			temp.push(this.props.nutrientNames[name]);
		}
		this.props.handleCurrentNutrients(temp);

	}

	render() {
		return (
			<div className="App">
				<Container>

					<Row>
						<Col sm={3}>
							<ListGroup>
								{Object.keys(this.props.nutrientNames).map((name) => (
									<ListGroup.Item action onClick={(e) => this.handleClick(name, e)} key={name}>
										{name}
									</ListGroup.Item>
								))}
							</ListGroup>
						</Col>

						<Col sm={9}>
							<Table striped bordered hover>
								<thead>
									<tr>
										{Object.keys(this.props.nutrientColumns).map((column) => (
											<th key={column}>{column}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{this.props.nutrientCurrent.map((nutrientKey) => (
										<tr key={nutrientKey}>
											{Object.values(this.props.nutrientColumns).map((columnKey) => (
												<td key={columnKey}>
													{((this.props.nutrient[nutrientKey][columnKey]) ?
														this.props.nutrient[nutrientKey][columnKey] :
														"")}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</Table>
						</Col>
					</Row>

				</Container>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		userStatus: state.userStatus,
		userName: state.userName,
		nutrient: state.nutrient,
		nutrientNames: state.nutrientNames,
		nutrientItems: state.nutrientItems,
		nutrientCurrent: state.nutrientCurrent,
		nutrientColumns: state.nutrientColumns,
		nutrientColumnOptions: state.nutrientColumnOptions,
		nutrientColumnInfo: state.nutrientColumnInfo
	}
}

export default connect(mapStateToProps, {
	handleGrabNutrientNames,
	handleGrabNutrientColumnOptions,
	handleGrabNutrientColumns,
	handleCurrentNutrients,
	handleGrabOneNutrient,
	handleGrabNutrientColumnInfo,
	handleGrabNutrientItems
})(NutrientHome);