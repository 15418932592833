import { GRAB_ONE_EXERCISE, CLEAR_EXERCISES } from '../../actions/exercise';


export default function exercises(state = {}, action) {
	switch (action.type) {
		case GRAB_ONE_EXERCISE:
			return { ...state, [action.key]: action.data };
		case CLEAR_EXERCISES:
			return {};
		default:
			return state;
	}
}