import { GRAB_MUSCLE_ITEMS, GRAB_MUSCLE_COLUMN_INFO, GRAB_ALL_MUSCLE } from '../actions/muscle';
import { GRAB_NUTRIENT_ITEMS, GRAB_NUTRIENT_COLUMN_INFO } from '../actions/nutrient';
import { GRAB_EXERCISE_ITEMS, GRAB_EXERCISE_COLUMN_INFO } from '../actions/exercise';
import { GRAB_FOOD_COLUMN_INFO } from '../actions/food';
import { TOGGLE_INFO } from '../actions/other';

export default function allItems(state = {
	allMuscles: false, muscleItems: false, muscleColumnInfo: false,
	nutrientItems: false, nutrientColumnInfo: false,
	exerciseItems: false, exerciseColumnInfo: false,
	foodColumnInfo: false, toggleInfo: false, allItems: {}
}, action) {
	switch (action.type) {
		case TOGGLE_INFO:
			return { ...state, toggleInfo: !state.toggleInfo }
		case GRAB_ALL_MUSCLE:
			if (!state.allMuscles) {
				let temp = {};
				let tempStr = "";
				let tempStr1 = "";
				for (let i = 0; i < Object.keys(action.data).length; i++) {
					tempStr = Object.keys(action.data)[i];
					tempStr1 = action.data[tempStr]["DACWBDJPHVUBHFZOOIQW"];
					temp[tempStr] = { info: tempStr1 };
				}
				return { ...state, allMuscles: true, allItems: { ...state.allItems, ...temp } };
			} else {
				return state;
			}
		case GRAB_MUSCLE_ITEMS:
			if (!state.muscleItems) {
				return { ...state, muscleItems: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
            }
		case GRAB_NUTRIENT_ITEMS:
			if (!state.nutrientItems) {
				return { ...state, nutrientItems: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		case GRAB_EXERCISE_ITEMS:
			if (!state.exerciseItems) {
				return { ...state, exerciseItems: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		case GRAB_MUSCLE_COLUMN_INFO:
			if (!state.muscleColumnInfo) {
				return { ...state, muscleColumnInfo: true,  allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		case GRAB_NUTRIENT_COLUMN_INFO:
			if (!state.nutrientColumnInfo) {
				return { ...state, nutrientColumnInfo: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		case GRAB_EXERCISE_COLUMN_INFO:
			if (!state.exerciseColumnInfo) {
				return { ...state, exerciseColumnInfo: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		case GRAB_FOOD_COLUMN_INFO:
			if (!state.foodColumnInfo) {
				return { ...state, foodColumnInfo: true, allItems: { ...state.allItems, ...action.data } };
			} else {
				return state;
			}
		default:
			return state;
	}
}