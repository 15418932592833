import { GRAB_RPE } from '../actions/rpe';


export default function rpe(state = {}, action) {
	switch (action.type) {
		case GRAB_RPE:
			return action.data;
		default:
			return state;
	}
}