import { GRAB_ONE_FOOD, CLEAR_FOOD } from '../../actions/food';


export default function food(state = {}, action) {
	switch (action.type) {
		case GRAB_ONE_FOOD:
			return { ...state, [action.key]: action.data };
		case CLEAR_FOOD:
			return {};
		default:
			return state;
	}
}