import { CHANGE_LOADING } from '../actions/other';


export default function loading(state = false, action) {
	switch (action.type) {
		case CHANGE_LOADING:
			return action.data;
		default:
			return state;
	}
}