import React from 'react';
import './App.css';
import Router from "./Router";
import { BrowserRouter } from 'react-router-dom';
import Header from './Header/Header';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

class App extends React.Component {	
  
  render(){
      return (
        <div>
            <BrowserRouter>
    	    <div className="App">
                <header>
                    <Header/>
                </header>
                  </div>

                  {((!this.props.loading) ?
                      <Router />
                      : <ReactLoading type='spinningBubbles' color='blue' height={'20%'} width={'20%'} />)}
            </BrowserRouter>
        </div>
      );
  }
}

const mapStateToProps = state => {
	return{
		user: state.user,
        userStatus: state.userStatus,
        loading: state.loading
	}
}

export default connect(mapStateToProps, {
})(App);
