import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from "./components/Login";
import Search from "./components/search";
import WIP from "./components/WIP";
import ExerciseHome from "./components/exerciseHome";
import FoodHome from "./components/foodHome";
import MuscleHome from "./components/muscleHome";
import NutrientHome from "./components/nutrientHome";
import RedirectPage from "./components/404";
import {withRouter} from 'react-router-dom';
import { handleUserStatus, handleGrabName } from './actions/user';
import { userId } from './firebaseAPI';
import { handleGrabExerciseNames } from './actions/exercise';


class Router extends Component {

	constructor(props){
		super(props);
        this.state = {
            status: false,
            };
	}

    componentDidMount() {
        if (this.props.userStatus & this.props.userName === "") {
            if (userId) {
                this.props.handleGrabName(userId.uid);
            } else {
                this.props.handleUserStatus(false);
            }
        }
	}


    render() {
      return (
        <div>
        {(this.props.userStatus) ? <>
                  <Switch>
                      <Route exact path="/"><Search /></Route>
                      <Route exact path="/workouts"><ExerciseHome /></Route>
                      <Route exact path="/recipes"><FoodHome /></Route>
                      <Route exact path="/assessment"><WIP /></Route>
                      <Route exact path="/muscles"><MuscleHome /></Route>
                      <Route exact path="/nutrient"><NutrientHome /></Route>
                      <Route exact path="/personalinfo"><WIP /></Route>
                      <Route exact path="/admin"><WIP /></Route>
                      <Route exact path="/login" component={Login} />
                <Route path="*"> 
                    <RedirectPage/>
                </Route>
                </Switch>
                </>
                : 
                <> 
                <Switch>
                <Route exact path="/login" component={Login} /> 
                <Redirect from="*" to="/login" />
                </Switch>
                </>}
                
        </div>
      );
   }
}

const mapStateToProps = state => {
	return{
        userStatus: state.userStatus,
        userName: state.userName
	}
}

export default withRouter(connect(mapStateToProps, {
    handleUserStatus,
    handleGrabName,
    handleGrabExerciseNames
})(Router));
