import { db, firebaseConfig } from '../firebaseAPI';
import { initializeApp } from 'firebase/app';
import { ref, get, child, update, getDatabase } from "firebase/database";
export const GRAB_NAME = 'GRAB_NAME';
export const HANDLE_USER_STATUS = 'HANDLE_USER_STATUS';

export const handleGrabName = (uid) => async dispatch => {

	const app = initializeApp(firebaseConfig);
	const db1 = ref(getDatabase(app));

	get(child(db1, `users/${uid}/name`)).then((snapshot) => {
		if (snapshot.exists()) {
			dispatch({
				type: GRAB_NAME,
				data: snapshot.val()
			})
		} else {
			console.log("No data available for handleGrabName action");
		}
	}).catch((error) => {
		console.error(error);
	});
}

export const handleFirebaseCreateUser = (uid, name) => async dispatch => {

	//const newPostKey = push(child(ref(db), 'users')).key;

	const updates = {};
	updates['/users/' + uid] = { name: name };

	dispatch({
		type: GRAB_NAME,
		data: name
	})

	return update(ref(db), updates);
}

export function handleUserStatus(bool){
	return{
		type: HANDLE_USER_STATUS,
		bool
	}
}
